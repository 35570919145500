<template>
	<div class="container">
		<div class="container_title"><img src="../../assets/img/datibefor_head.png" alt=""></div>
		<div class="container_box">
			<div class="container_box_title">
				赛前悉知>>
			</div>
			<div class="container_box_text">
				<!-- <div class="number">1</div> -->
				<div class="title">
					1、每次答题时间为40分钟；</br>
					2、每位参赛者不限制答题次数，比赛成绩取最好成绩计入排名；</br>
					3、比赛按照排名获奖，教师组前300名，小学组、中学组分别前2000名，学校参赛人数排名前30名均可获得荣誉证书；</br>
					4、比赛题型：单选题（50题，1分1题）、多选题（30题，答对且答全2分，答对没答全1分）、判断题（20题，1分）</br>
				</div>
			</div>
			<div class="container_btn">
				<div class="container_btn_box" v-if="flag" @click="handleClick">
					<div>我已阅读 开始答题</div>
					<div>（{{ num }}秒倒计时）</div>
				</div>
				<div class="container_btn_box active" v-if="!flag" @click="handleClick">
					<div>我已阅读 开始答题</div>
				</div>
				<div class="container_btn_box container_btn" @click="$router.push({name: 'info',query: {type:$route.query.type}})">
					<div>修改信息</div>
				</div>
			</div>
		</div>
		
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		getInfo,
	} from '@/api'
	export default {
		name: "notice",
		data() {
			return {
				flag: true,
				timer1: null,
				num: 30,
				type: '',
				starttime: '',
				expiretime: '',
			}
		},
		created() {
			this.countDown()
			this.getUser()
			localStorage.setItem('flag', '0')
		},

		methods: {
			countDown() {
				this.timer1 = setInterval(() => {
					this.num--
					if (this.num == 0) {
						clearInterval(this.timer1)
						this.flag = false
						this.handleClick()
					}
				}, 1000)
			},
			handleClick() {
				let nowTime = parseInt(new Date().getTime()/1000)
				// if(this.starttime>nowTime || nowTime>this.expiretime){
				// 	this.$toast.fail('活动不在开启时间')
				// 	return false
				// }
				this.$router.replace({
					name: 'dati',
					query: {
						id: 1,
					}
				})
			},
			getUser(){
				let data = {
					token: localStorage.getItem('token')
				}
				getInfo(data).then(res => {
					if(res.data.code==0){
						this.$toast.fail(res.data.msg)
					}else{
						this.starttime = res.data.data.rexam.starttime
						this.expiretime = res.data.data.rexam.expiretime
					}
					
				})
			},
		},
		beforeDestroy() {
			clearInterval(this.timer1)

		}
	}
</script>

<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding-bottom: 50px;
		background: url(../../assets/img/allbanner.png) no-repeat center 0;
		background-size: 100% 100%;
		.container_title{
			box-sizing: border-box;
			width: 100%;
			padding: 100px 0px 20px 0px;
			text-align: center;
			font-size: 55px;
			color: #fff;
			letter-spacing: 0.3em;
			img{
				width: 80%;
			}
		}
			.container_box {
				padding: 80px 30px 40px;
				width: 650px;
				// height: 950px;
				box-sizing: border-box;
				border-radius: 25px;
				background-color: #fff;
				margin: auto;
				&_title {
					font-size: 35px;
					font-weight: bold;
				}

				&_text {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-around;
					margin-top: 40px;

					.number {
						color: #fff;
						width: 45px;
						height: 45px;
						border-radius: 50%;
						background-color: #FFCA36;
						font-size: 35px;
						text-align: center;
						font-weight: 500;
						line-height: 45px;
						margin-top: 10px;
					}

					/* .number:after {
						content: '';
						height: 100px;
						width: 0px;
						border: 1px dashed #FFCA36;
						display: block;
						margin: 15px 0 0 20px;
					}

					.number3 {
						color: #fff;
						width: 45px;
						height: 45px;
						border-radius: 50%;
						background-color: #FFCA36;
						font-size: 35px;
						text-align: center;
						font-weight: 500;
						line-height: 45px;
					} */

					.title {
						width: 550px;
						font-size: 25px;
						font-weight: 600;
						letter-spacing: 4px;
						text-align: justify;
						line-height: 50px;
					}
				}
			}

		&_btn {
			width: 100%;
			text-align: center;
			padding: 52px 62px 0 62px;
			box-sizing: border-box;

			.container_btn_box {
				background-color: #FFA028;
				border-radius: 10px;
				font-size: 29px;
				color: #fff;
				padding: 25px 0 25px 0;
				font-weight: 600;

				div {
					margin-bottom: 10px;
				}

				&:nth-child(3) {
					margin-bottom: 0;
				}
			}
			.container_btn{
				background: #36ced1;
				margin-top: 20px;
			}
		}

		.active {
			background: #FFA028;
			color: #fff;
		}
	}
</style>
